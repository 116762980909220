<template>
  <div class="app-container">
    <div class="main-body">
      <el-row class="call-title">
        <el-col :span="24">
          <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
          <span class="title">租户管理</span>
        </el-col>
      </el-row>
      <el-row>
        <search-table
          ref="searchTable"
          labelWidth="70px"
          :loading="listLoading"
          :searchFields="searchFields"
          :autoBtnList="autoBtnList"
          :colSize="colSize"
          :total="total"
          :tableData="dataList"
          :tableColumns="tableColumns"
          :baseColumns="baseColumns"
          :sortFields="sortFields"
          :checkboxColumn="true"
          :hasPagination="true"
          :showFooter="true"
          :showSetting="true"
          :showColumnSearch="true"
          :showSortable="true"
          :tableSummary="tableSummary"
          :proxyParams="proxyParams"
          :selectTableSummary="selectTableSummary"
          @saveTableField="saveTableField"
          @saveSearchField="saveSearchField"
          @saveSortField="saveSortField"
          @getTableData="queryList"
        >
        </search-table>
      </el-row>
    </div>
    <el-dialog :visible.sync="dialogVisible" :title="dialogTitle" width="70%">
      <el-form ref="formData" :model="formData" :rules="rules" label-width="130px">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="客户类型/名称：" prop="name">
              <el-select size="small" style="width: 30%" v-model="formData.type" placeholder="" prop="name">
                <el-option label="企业" value="ENTERPRISE" />
                <el-option label="个人" value="PERSONAL" />
              </el-select>
              <el-input v-model="formData.name" size="small" max="80" class="input-width-6" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人：" prop="contact">
              <el-input v-model="formData.contact" size="small" max="60" class="input-width-8" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="联系电话：" prop="phone">
              <el-input v-model="formData.phone" size="small" max="11" class="input-width-8" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="到期日期：" prop="dueDate">
              <el-date-picker v-model="formData.dueDate" size="small" class="input-width-8" type="date" placeholder="选择到期日期"> </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="年服务费(元)：" prop="serviceFee">
              <el-input v-model="formData.serviceFee" size="small" type="number" class="input-width-8" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户经理：" prop="sellerId">
              <el-select v-model="formData.sellerId" size="small" class="input-width-8">
                <el-option v-for="(item, index) in sellerList" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所在地区：" prop="city">
              <el-cascader filterable class="input-width-8" size="small" :options="options" v-model="selectedOptions" @change="handleChange">
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitData">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { regionData } from 'element-china-area-data'
import { queryList, batchStatus, batchVerify, tenantSave, tenantEdit, getSellerList } from '@/api/tenant'
import SearchTable from '@/components/LcSearchTable'
import config from '@/const/tenant/index.js'
import { formatDate } from '@/utils/time'
import { columnSetting } from '@/api/common'
import { isParamsNotNull } from '@/utils/util'
export default {
  components: {
    SearchTable
  },
  data: function () {
    const validatPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    const validatNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('输入数字不能为空'))
      } else {
        const reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入大于0的实数'))
        }
      }
    }
    return {
      options: regionData,
      selectedOptions: [],
      total: 0,
      summary: {},
      dataList: [],
      sellerList: [], // 客户经理列表
      dialogTitle: '添加客户',
      dialogVisible: false,
      listLoading: false,
      formData: {
        id: '', // 修改时必传
        city: '', // 市
        contact: '', // 联系人
        district: '', // 县
        dueDate: '', //结束到期日期
        name: '', // 名字
        phone: '', // 联系电话
        province: '', // 省
        sellerId: '', //销售员
        serviceFee: '', //服务费用
        startDate: '', //开始到期日期
        type: '' // 类型
      },
      rules: {
        contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }], // 联系人
        dueDate: [{ required: true, message: '请选择结束到期日期', trigger: 'blur' }], //结束到期日期
        name: [{ required: true, message: '请输入客户名称', trigger: 'blur' }], // 名字
        phone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          {
            validator: validatPhone,
            message: '请输入正确的电话',
            trigger: 'blur'
          }
        ], // 联系电话
        sellerId: [{ required: true, message: '请输入销售员', trigger: 'blur' }], //销售员
        serviceFee: [
          { required: true, message: '请输入服务费用', trigger: 'blur' },
          {
            validator: validatNumber,
            message: '请输入大于0的实数',
            trigger: 'blur'
          }
        ], //服务费用
        startDate: [{ required: true, message: '请选择开始到期日期', trigger: 'blur' }], //开始到期日期
        type: [{ required: true, message: '请选择客户类型', trigger: 'blur' }] // 类型
      },
      // 查询条件
      formParams: {
        name: '', // 名字
        seller: '', //销售员
        phone: '', // 联系电话
        serviceFee: '', //服务费用
        type: '', // 类型 PERSONAL, ENTERPRISE
        dateType: '1', // 1-启动日期 2-到期时间
        current: 1,
        size: 100
      },
      checkedRecords: [], // 表格已经选中的行数据
      baseColumn: [], //接口请求的表格配置
      baseColumns: [],
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 3, //用户设置展示列比例
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
      sortFields: [], // 用户自定义的排序设置
      formParams: {}, // 当前查询的参数
      path: '/system/tenant/findByPage' // 更新字段名显示接口路径
    }
  },
  activated() {
    this.queryList()
    this.getSellerList()
  },
  computed: {
    // 操作按钮
    autoBtnList() {
      return config.operateList(this)
    }
  },
  mounted() {
    // this.searchFields = config.searchFields(this)
    // this.baseColumns = config.tableFields(this)
    // this.tableColumns = config.tableFields(this)
  
  },
  methods: {
    //获取客户经理列表
    getSellerList() {
      let params = {
        name: ''
      }
      getSellerList(params).then(res => {
        this.sellerList = res.data
      })
    },
    // 所在地区选择改变
    handleChange(value) {
      let names = this.getDistrictByCode(value[0], value[1], value[2])
      this.formData.province = names[0]
      this.formData.city = names[1]
      this.formData.district = names[2]
      //console.log(this.getDistrictCodeByName('天津市', '市辖区', '河西区'))
    },
    //根据代码获取省市县名称
    getDistrictByCode(province, city, district) {
      let names = []
      // "150000", "150500", "150523"
      let provinceData = regionData.filter(item => {
        return item.value === province
      })
      names.push(provinceData[0].label)
      let cityData = provinceData[0].children.filter(item => {
        return item.value === city
      })
      names.push(cityData[0].label)

      let districtData = cityData[0].children.filter(item => {
        return item.value === district
      })
      names.push(districtData[0].label)
      return names
    },
    // 根据名称获取代码 天津市", "市辖区", "河西区
    getDistrictCodeByName(province, city, district) {
      let codes = []
      let provinceData = regionData.filter(item => {
        return item.label === province
      })
      codes.push(provinceData[0].value)
      let cityData = provinceData[0].children.filter(item => {
        return item.label === city
      })
      codes.push(cityData[0].value)
      let districtData = cityData[0].children.filter(item => {
        return item.label === district
      })
      codes.push(districtData[0].value)
      return codes
    },
    // 新增
    addRowHandler() {
      this.resetForm()
      this.dialogTitle = '添加客户'
      this.dialogVisible = true
    },
    // 编辑
    editRowHandler() {
      this.selectedOptions = []
      let len = this.$refs.searchTable.selectTableData.length
      const selectTableData = this.$refs.searchTable.selectTableData
      if (len === 0) {
        this.$message.error('请选中一行数据')
      }
      if (len === 1) {
        const row = Object.assign(this.formData, selectTableData[0])
        if (row.province && row.city && row.district) {
          this.selectedOptions = this.getDistrictCodeByName(row.province, row.city, row.district)
        }
        this.formData = row
        this.dialogTitle = '修改客户信息'
        this.dialogVisible = true
      } else {
        this.$message.error('只能选择一行数据')
      }
    },
    // 冻结
    freezeHandler() {
      let len = this.$refs.searchTable.selectTableData.length
      const selectTableData = this.$refs.searchTable.selectTableData
      if (len === 0) {
        this.$message.error('请至少选中一行')
      }
      if (len > 0) {
        // 过滤选中行中可以冻结的行数据
        let rows = selectTableData.filter(item => {
          return item.status === 'ENABLE'
        })
        let arr = []
        rows.forEach(item => {
          arr.push(item.id)
        })
        // 将多条数据id用分号隔开传给后端
        let ids = arr.join(',')
        if (!ids) {
          this.$message.error('选中的数据无法进行冻结操作')
          return
        }
        let params = {
          ids: ids,
          status: 'DISABLE'
        }
        let _this = this
        batchStatus(params).then(res => {
          if (res.code === 200) {
            this.$message.success('冻结成功')
            this.$refs.searchTable.selectTableData = []
            this.queryList()
          } else {
            this.$message.error('冻结失败')
          }
        })
      }
    },
    // 解冻
    thawHandler() {
      let len = this.$refs.searchTable.selectTableData.length
      const selectTableData = this.$refs.searchTable.selectTableData
      if (len === 0) {
        this.$message.error('请至少选中一行')
      }
      if (len > 0) {
        // 过滤选中行中可以冻结的行数据
        let rows = selectTableData.filter(item => {
          return item.status === 'DISABLE'
        })
        let arr = []
        rows.forEach(item => {
          arr.push(item.id)
        })
        // 将多条数据id用分号隔开传给后端
        let ids = arr.join(',')
        if (!ids) {
          this.$message.error('选中的数据无法进行解冻操作')
          return
        }
        let params = {
          ids: ids,
          status: 'ENABLE'
        }
        batchStatus(params).then(res => {
          if (res.code === 200) {
            this.$message.success('解冻成功')
             this.$refs.searchTable.selectTableData = []
            this.queryList()
          } else {
            this.$message.error('解冻失败')
          }
        })
      }
    },
    /**
     * 通过认证
     */
    passAuthHandler() {
      let len = this.$refs.searchTable.selectTableData.length
      const selectTableData = this.$refs.searchTable.selectTableData
      if (len === 0) {
        this.$message.error('请至少选中一行')
      }
      if (len > 0) {
        // 过滤选中行中可以冻结的行数据
        let rows = selectTableData.filter(item => {
          return item.approveStatus === 'APPROVING' || item.approveStatus === 'REJECT'
        })
        let arr = []
        rows.forEach(item => {
          arr.push(item.id)
        })
        // 将多条数据id用分号隔开传给后端
        let ids = arr.join(',')
        if (!ids) {
          this.$message.error('选中的数据无法进行通过认证操作')
          return
        }
        let params = {
          ids: ids,
          approveStatus: 'APPROVED'
        }
        batchVerify(params).then(res => {
          if (res.code === 200) {
            this.$message.success('通过认证成功')
             this.$refs.searchTable.selectTableData = []
            this.queryList()
          } else {
            this.$message.error('通过认证失败')
          }
        })
      }
    },
    /**
     * 驳回认证
     */
    rejectAuthHandler() {
      let len = this.$refs.searchTable.selectTableData.length
      const selectTableData = this.$refs.searchTable.selectTableData
      if (len === 0) {
        this.$message.error('请至少选中一行')
      }
      if (len > 0) {
        // 过滤选中行中可以冻结的行数据
        let rows = selectTableData.filter(item => {
          return item.approveStatus === 'APPROVING' || item.approveStatus === 'APPROVED'
        })
        let arr = []
        rows.forEach(item => {
          arr.push(item.id)
        })
        // 将多条数据id用分号隔开传给后端
        let ids = arr.join(',')
        if (!ids) {
          this.$message.error('选中的数据无法进行驳回认证操作')
          return
        }
        let params = {
          ids: ids,
          approveStatus: 'REJECT'
        }
        batchVerify(params).then(res => {
          if (res.code === 200) {
            this.$message.success('驳回认证成功')
             this.$refs.searchTable.selectTableData = []
            this.queryList()
          } else {
            this.$message.error('驳回认证失败')
          }
        })
      }
    },
    // 查询
    async queryList(params) {
      if (params) {
        this.formParams = params
      } else {
        params = this.formParams
      }
      params = isParamsNotNull(params)
      // if (params.startDate && params.startDate.length) {
      //   params.startStartDate = params.startDate[0]
      //   params.endStartDate = params.startDate[1]
      // }
      // if (params.dueDate && params.dueDate.length) {
      //   params.startDueDate = params.dueDate[0]
      //   params.endDueDate = params.dueDate[1]
      // }
      this.listLoading = true
      const res = await queryList({ ...params })
      if (res.code === 0) {
        this.dataList = res.data.items
        this.total = res.data.total * 1
        this.tableSummary = res.data.summary
        this.baseColumns = res.data.tableSettingFields || config.baseColumn(this)
        this.path = res.data.path || this.path
        this.colSize = res.data.colSize
        this.initConfig()
        // console.log(this.baseColumns)
      }
      this.listLoading = false
      //})
    },
    initConfig() {
      this.tableColumns = this.baseColumns.filter(v => v.showField)
      this.searchFields = this.baseColumns.filter(v => v.isSearchShow)
      this.sortFields = this.tableColumns
        .filter(v => v.sortable)
        .reduce((prev, item) => {
          // if(item.sortList) prev = prev.concat(item.sortList)
          prev.push({
            title: `${item.title}升序`,
            field: `${item.field}-asc`
          })
          prev.push({
            title: `${item.title}降序`,
            field: `${item.field}-desc`
          })
          return prev
        }, [])
    },
    // 提交数据
    submitData() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在保存...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.6)',
            target: document.getElementById('el-dialog')
          })
          // 如果id不为空执行更新 否则执行新增
          if (this.formData.id) {
            tenantEdit(this.formData).then(res => {
              if (res.code === 200) {
                this.dialogVisible = false
                this.selectedOptions = []
                this.$message.success('修改客户信息成功')
                 this.$refs.searchTable.selectTableData = []
                this.queryList()
                this.resetForm()
              } else {
                this.$message.error(res.msg)
              }
              loading.close()
            })
          } else {
            tenantSave(this.formData).then(res => {
              if (res.code === 200) {
                this.dialogVisible = false
                this.selectedOptions = []
                this.$message.success('创建客户成功')
                this.queryList()
                this.resetForm()
              } else {
                this.$message.error(res.msg)
              }
              loading.close()
            })
          }
        } else {
          return
        }
      })
    },
    // 时间格式化
    dateFormat(data) {
      return formatDate(data)
    },
    // 重置表单
    resetForm() {
      this.formData = {
        id: '', // 修改时必传
        city: '', // 市
        contact: '', // 联系人
        district: '', // 县
        dueDate: '', //结束到期日期
        name: '', // 名字
        phone: '', // 联系电话
        province: '', // 省
        seller: '', //销售员
        serviceFee: '', //服务费用
        startDate: '', //开始到期日期
        type: '' // 类型
      }
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      this.tableColumns = fields
      try {
        const res = await columnSetting({
          path: this.path,
          // searchFields: this.searchFields,
          tableFields: fields
        })
        this.getTableData()
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      // console.log(fields, colSize)
      this.colSize = colSize
      this.searchFields = fields
      try {
        const res = await columnSetting({
          path: this.path,
          searchFields: fields,
          // tableFields: this.tableColumns,
          colSize
        })
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改排序字段配置
    async saveSortField(fields) {
      this.sortFields = fields
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },

    //获取表格头配置
    async getTableField() {},
    //获取搜索字段配置
    async getSearchField() {}
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}
.icon-cls-green {
  color: #67c23a;
}
.dialog-row {
  margin-top: 10px;
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}
.el-pagination {
  float: right;
}
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
  background-color: #dfecfb;
}
.vxe-table--footer .col-blue {
  background: #f2f5f0;
}
/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}
.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}
.write-color {
  border-color: #f0f0f0;
}
/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}
.input-width-7 {
  width: 70% !important;
}
.input-width-8 {
  width: 80% !important;
}
</style>
