const baseColumn = vm => {
  return  [
    {
      keyId: 1,
      title: '客户名称',
      field: 'name',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      isSearchShow:true,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 2,
      title: '客户类型',
      field: 'type',
      width: 120,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow:true,
      selectOptions: null,
      showField: true,
      enum:true,
      selectOptions: [
        {
          label: '个人',
          value: 'PERSONAL'
        },
        {
          label: '企业',
          value: 'ENTERPRISE'
        }
      ],
      fieldEnumValueList: {
        PERSONAL: '个人',
        ENTERPRISE: '企业'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 3,
      title: '公司代码',
      field: 'code',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 3,
      title: '城市',
      field: 'city',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 6,
      title: '租户状态',
      field: 'status',
      width: 120,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow:true,
      enum:true,
      selectOptions: [
        {
          label: '正常',
          value: 'ENABLE'
        },
        {
          label: '禁用',
          value: 'DISABLE'
        }
      ],
      fieldOperateTag:{
        ENABLE: 'success',
        DISABLE: 'warning'
      },
      fieldEnumValueList: {
        ENABLE: '正常',
        DISABLE: '冻结'
      },
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: '#e6a23c',
      fixed: null,
      renderType: 'status',
      copy: false
    },
    {
      keyId: 6,
      title: '认证状态',
      field: 'approveStatus',
      width: 120,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow:true,
      enum:true,
      selectOptions: [
        {
          label: '正常',
          value: 'APPROVED'
        },
        {
          label: '待认证',
          value: 'APPROVING'
        },
        {
          label: '驳回',
          value: 'REJECT'
        }
      ],
      fieldOperateTag:{
        APPROVED: 'success',
        APPROVING: 'warning',
        REJECT: 'danger'
      },
      fieldEnumValueList: {
        APPROVED: '正常',
        APPROVING: '待认证',
        REJECT: '驳回'
      },
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: '#e6a23c',
      fixed: null,
      renderType: 'status',
      copy: false
    },
    {
      keyId: 7,
      title: '联系人',
      field: 'contact',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: false,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 8,
      title: '联系电话',
      field: 'phone',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 8,
      title: '启用日期',
      field: 'startDate',
      width: 120,
      fieldType: null,
      searchType: 'daterange',
      searchValue: '',
      isSearchShow:true,
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true,
      columnType: 'date', // 筛选自定义
      filterable: false // 是否可筛选
    },
    {
      keyId: 10,
      title: '到期日期',
      field: 'dueDate',
      width: 120,
      fieldType: null,
      searchType: 'daterange',
      searchValue: '',
      isSearchShow:true,
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true,
      columnType: 'date', // 筛选自定义
      filterable: false // 是否可筛选
    },
    {
      keyId: 11,
      title: '服务费金额(元)',
      field: 'serviceFee',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 12,
      title: '客户经理',
      field: 'seller',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}

// 操作按钮配置项
const operateList = vm => {
  return [
    { name: '创建客户', btnFn: vm.addRowHandler, plain: true, icon: 'el-icon-circle-plus' },
    { name: '修改', btnFn: vm.editRowHandler,  plain: true, icon: 'el-icon-edit' },
    { name: '通过认证', btnFn: vm.passAuthHandler, plain: true, icon: 'el-icon-check' },
    { name: '驳回认证', btnFn: vm.rejectAuthHandler, plain: true, icon: 'el-icon-close' },
    { name: '冻结', btnFn: vm.freezeHandler, plain: true, icon: 'el-icon-lock' },
    { name: '解冻', btnFn: vm.thawHandler, plain: true, icon: 'el-icon-unlock' }
  ]
}
// 搜索字段配置项
const searchFields = vm => {
  return [
    {
      keyId: 1,
      title: '启动日期',
      field: 'startDueDate',
      width: 240,
      searchType: 'daterange',
      searchValue: '', //搜索绑定值
      sortable: false, //是否排序
      copy: true, //是否拥有复制功能
      columnType: 'date', // 筛选自定义
      filterable: true // 是否可筛选
    },
    {
      keyId: 2,
      title: '客户名称',
      field: 'name',
      width: 200,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true //是否拥有复制功能
    },
    {
      keyId: 3,
      title: '客户类型',
      field: 'type',
      width: 200,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      renderType: 'status', //achnor,status,option
      sortable: true, //是否排序
      textColor: '#e6a23c',
      copy: true, //是否拥有复制功能
      selectOptions: [
        {
          label: '个人',
          value: 'PERSONAL'
        },
        {
          label: '企业',
          value: 'ENTERPRISE'
        }
      ],
    },
    {
      keyId: 11,
      title: '客户经理',
      field: 'seller',
      width: 200,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: false, //是否排序
      copy: true //是否拥有复制功能
    },
    {
      keyId: 6,
      title: '租户状态',
      field: 'status',
      width: 200,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      renderType: 'status', //achnor,status,option
      sortable: true, //是否排序
      textColor: '#e6a23c',
      copy: true, //是否拥有复制功能
      selectOptions: [
        { label: '全部', value: '' },
        { label: '正常', value: 'ENABLE' },
        { label: '冻结', value: 'DISABLE' }
      ]
    },
    {
      keyId: 7,
      title: '认证状态',
      field: 'approveStatus',
      width: 200,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      renderType: 'status', //achnor,status,option
      sortable: true, //是否排序
      textColor: '#e6a23c',
      copy: true, //是否拥有复制功能
      selectOptions: [
        {
          label: '正常',
          value: 'APPROVED'
        },
        {
          label: '待认证',
          value: 'APPROVING'
        },
        {
          label: '驳回',
          value: 'REJECT'
        }
      ],
    }
  ]
}
// 表格列配置
const tableFields = vm => {
  return [
    {
      keyId: 1,
      title: '客户名称',
      field: 'name',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 2,
      title: '客户类型',
      field: 'type',
      width: 120,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum:true,
      fieldEnumValueList: {
        PERSONAL: '个人',
        ENTERPRISE: '企业'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 3,
      title: '公司代码',
      field: 'code',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 3,
      title: '城市',
      field: 'city',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 6,
      title: '租户状态',
      field: 'status',
      width: 120,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      enum:true,
      selectOptions: [
        {
          label: '正常',
          value: 'ENABLE'
        },
        {
          label: '冻结',
          value: 'DISABLE'
        }
      ],
      fieldOperateTag:{
        ENABLE: 'success',
        DISABLE: 'warning'
      },
      fieldEnumValueList: {
        ENABLE: '正常',
        DISABLE: '冻结'
      },
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: '#e6a23c',
      fixed: null,
      renderType: 'status',
      copy: false
    },
    {
      keyId: 6,
      title: '认证状态',
      field: 'approveStatus',
      width: 120,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      enum:true,
      selectOptions: [
        {
          label: '正常',
          value: 'APPROVED'
        },
        {
          label: '待认证',
          value: 'APPROVING'
        },
        {
          label: '驳回',
          value: 'REJECT'
        }
      ],
      fieldOperateTag:{
        APPROVED: 'success',
        APPROVING: 'warning',
        REJECT: 'danger'
      },
      fieldEnumValueList: {
        APPROVED: '正常',
        APPROVING: '待认证',
        REJECT: '驳回'
      },
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: '#e6a23c',
      fixed: null,
      renderType: 'status',
      copy: false
    },
    {
      keyId: 7,
      title: '联系人',
      field: 'contact',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: false,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 8,
      title: '联系电话',
      field: 'phone',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 8,
      title: '启用日期',
      field: 'startDate',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 10,
      title: '到期日期',
      field: 'dueDate',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 11,
      title: '服务费金额(元)',
      field: 'serviceFee',
      width: 160,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 12,
      title: '客户经理',
      field: 'seller',
      width: 120,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}
export default {
  operateList,
  searchFields,
  tableFields,
  baseColumn
}
